<template>
  <div>
    <b-modal
      id="modal-add-dress"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('SubDress')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('Category')"
          style="width: 100%;"
        >
          <vue-select
            v-model="form.lovDressCategoryId"
            :options="getDressCategory"
            label="name"
            :reduce="(e) => e.lovDressCategoryId"
            :placeholder="$t('SelectCategory')"
            aria-describedby="input-1-live-feedback"
            :clearable="false"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Code')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.code"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>

        <b-form-group />
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required, minLength,
} from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [validationMixin],

  data() {
    return {
      form: {
        lovDressCategoryId: null,
        name: '',
        code: null,
      },
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        // alpha,
      },
    },
  },
  computed: {
    ...mapGetters(['getDressCategory']),
  },
  mounted() {
    this.dressCategories()
  },
  methods: {
    ...mapActions(['dressCategories']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('addDresses', this.form)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.name = ''
      this.form.lovDressCategoryId = null
      this.form.code = null
    },
  },
}
</script>

<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }

.flex-form {
  display: flex;
  flex-direction: column;
}

button{
  margin-top: 10px;
}
</style>
